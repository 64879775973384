<template>
  <div
    class="app-content content"
    :class="[{ 'show-overlay': $store.state.app.shallShowOverlay }, $route.meta.contentClass]"
  >
    <b-alert
      v-if="!isDashboard && !user_complete && !show && $store.state.auth.isProfileCalled"
      :show="!isDashboard && !user_complete"
      variant="danger"
      class="p-2"
    >
      <div v-if="!$store.state.auth.user.isActive && $store.state.auth.user.isActive != 1">
        <h2>{{ $t('user_no_active.register_success_confirm_email') }}</h2>
        <p>{{ $t('user_no_active.description1') }}</p>
        <hr>
        <p class="mb-2">
          {{ $t('user_no_active.email_process') }} {{ $t('user_no_active.email_resend') }}
        </p>
        <b-button
          :disabled="sendMaliLoading || !(remainingTime.minutes === 0 && remainingTime.seconds === 0)"
          variant="success"
          @click="resendActivationEmail()"
        >
          <span>{{ $t('user_no_active.resend_email_btn') }}</span>
          <span
            v-if="remainingTime.minutes > 0 || remainingTime.seconds > 0"
            class="mx-1"
          >

            {{ formatTime(remainingTime.seconds) }}
            :
            {{ formatTime(remainingTime.minutes) }}

          </span>
        </b-button>
      </div>
      <div
        v-else-if="$store.state.auth.isProfileCalled"
      >
        <h3>{{ $t(`${cardInfo.nextStep.header }`) }}</h3>
        <hr v-if="!isInHiddenRoute">
        <p
          v-if="!isInHiddenRoute"
          :class="{'mb-2':!isInHiddenRoute}"
        >
          {{ $t(`${ cardInfo.nextStep.description }`) }}
        </p>
        <b-button
          v-if="!isInHiddenRoute"
          :to="cardInfo.nextStep.btn_link"
        >

          {{ $t(`${cardInfo.nextStep.btn_text }`) }}
        </b-button>
      </div>
    </b-alert>
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BButton,
  BAlert,
} from 'bootstrap-vue'

import AppBreadcrumb from './AppBreadcrumb.vue'
import appFlow from '@/mixins/appFlow'
import ProfileApis from '@/services/apis/profile'

const profile = new ProfileApis()

export default {
  components: {
    BButton,
    AppBreadcrumb,
    BAlert,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  mixins: [appFlow],
  data() {
    return {
      errorShow: false,
      sendMaliLoading: false,
      remainingTime: {
        minutes: 0,
        seconds: 0,
      },
      remainingSeconds: 0,
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    isDashboard() {
      return this.$route.name === 'dashboard'
    },
    isActive() {
      return this.$store.state.auth.user.isActive
    },
    isInHiddenRoute() {
      if (this.cardInfo.nextStep.hiddenRoutes) {
        return this.cardInfo.nextStep.hiddenRoutes.find(item => {
          console.log(this.$route.fullPath, `/${this.$i18n.locale}/${item}`)
          return (item.name ? (this.$route.name === item.name) : (this.$route.fullPath === `/${this.$i18n.locale}${item}`))
        })
      }
      return false
    },
  },
  watch: {
    lang(val, old) {
      const exchangeElement = document.querySelector(`a[href='${`${process.env.VUE_APP_EXCHANGE_ADDRESS}/${old}/${process.env.VUE_APP_EXCHANGE_PAGE}`}']`)
      if (exchangeElement) {
        exchangeElement.href = `${process.env.VUE_APP_EXCHANGE_ADDRESS}/${val}/${process.env.VUE_APP_EXCHANGE_PAGE}`
      }
    },
    isActive() {
      this.exchangeLinkAction()
    },
  },
  mounted() {
    const exchangeElement = document.querySelector(`a[href='${`${process.env.VUE_APP_EXCHANGE_ADDRESS}`}']`)
    if (exchangeElement) {
      exchangeElement.target = '_self'
      exchangeElement.href = `${process.env.VUE_APP_EXCHANGE_ADDRESS}/${this.$i18n.locale}/${process.env.VUE_APP_EXCHANGE_PAGE}`
    }
    this.exchangeLinkAction()
    if (!this.isUserActive && !this.user_complete) {
      this.checkAndCallTimer()
    }
    this.crispCHat()
  },

  methods: {
    crispCHat() {
      const supportCHat = process.env.VUE_APP_SUPPORT_CHAT
      if (supportCHat && supportCHat === 'true') {
        const siteId = process.env.VUE_APP_SUPPORT_CHAT_WEBSITE_ID
        window.crisp = []
        window.CRISP_WEBSITE_ID = siteId;
        (function () {
          const d = document
          const s = d.createElement('script')
          s.src = 'https://client.crisp.chat/l.js'
          s.async = 1
          d.getElementsByTagName('head')[0].appendChild(s)
        }())
      }
    },
    resendActivationEmail() {
      this.sendMaliLoading = true
      profile.reSendActivationMail({ email: this.$store.state.auth.user.email }).then(res => {
        this.remainingSeconds = res.data.results.remained_expiration_time
        if (this.remainingSeconds) {
          this.updateCountdown()
        }
        this.$swal({
          title: `${this.$t('activationEmailSended')}`,
          icon: 'success',
          timer: 2500,
          showConfirmButton: true,
        })
      }).catch(err => {
        if (err.data.status === 406) {
          this.remainingSeconds = err.data.results.remained_expiration_time
          if (this.remainingSeconds) {
            this.updateCountdown()
          }
        }
      })
        .finally(() => {
          this.sendMaliLoading = false
        })
    },

    exchangeLinkAction() {
      const exchangeElement = document.querySelector(`a[href='${process.env.VUE_APP_EXCHANGE_ADDRESS}/${this.$i18n.locale}/${process.env.VUE_APP_EXCHANGE_PAGE}']`)
      if (exchangeElement) {
        if (this.isActive === '0') {
          exchangeElement.onclick = () => {
            this.$swal({
              title: this.$t('exchange_menu_click_user_no_active'),
              icon: 'warning',
              timer: 3000,
              showConfirmButton: false,
            })
            return false
          }
          exchangeElement.classList.add('disabled-link')
        } else {
          exchangeElement.removeAttribute('onclick')
          exchangeElement.classList.remove('disabled-link')
        }
      }
    },
    updateCountdown() {
      if (this.remainingSeconds > 0) {
        this.remainingTime.minutes = Math.floor(this.remainingSeconds / 60)
        this.remainingTime.seconds = this.remainingSeconds % 60
        this.remainingSeconds -= 1

        setTimeout(this.updateCountdown, 1000) // Call itself after 1 second
      } else {
        this.remainingTime.minutes = 0
        this.remainingTime.seconds = 0
      }
    },
    formatTime(value) {
      return value.toString().padStart(2, '0')
    },
    checkAndCallTimer() {
      if (this.$store.state.auth.user.email) {
        if (!this.$store.state.auth.user.isActive) {
          this.resendActivationEmail()
        }
      } else {
        setTimeout(this.checkAndCallTimer, 1000) // 1-second delay between each check
      }
    },
  },
}
</script>
